import React, { useState } from "react"
import FooterGallery from "../components/footer-gallery"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import RomanceList from "../components/romance/romance-list"
import SEO from "../components/seo"
import Subnavigation from "../components/subnavigation"
import TitleBlock from "../components/title-block"

const RomancePage = ({ data }) => {
    const [currentSection, setCurrentSection] = useState("#engagements")

    const romanceData = data.romanceData.romance
    const romanceItems = romanceData.romanceItems
    const {footerGallery} = romanceData

    const menu = romanceItems.map((romance, index) => ({
        title: romance.title,
        anchor: `#${romance.title[0]}-${index}`,
    }))

    return (
        <Layout>
            <SEO title="Romance" />
            <PageHeader title="Romance" />
            <Subnavigation currentSection={currentSection} menu={menu} />
            <TitleBlock title={romanceData.title} />
            <RomanceList
                romanceItems={romanceItems}
                setCurrentSection={setCurrentSection}
            />
            {footerGallery && footerGallery.length > 0 &&
                <FooterGallery gallery={footerGallery} />
            }
        </Layout>
    )
}

export default RomancePage

export const RomanceQuery = graphql`
    query romanceQuery {
        romanceData: wpPage(title: { eq: "Romance" }) {
            romance {
                footerGallery {
                    sourceUrl
                }
                title
                romanceItems {
                    title
                    image {
                        sourceUrl
                    }
                    description
                    type
                }
            }
        }
    }
`
